<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol
              :span="24"
              style="max-height: 65vh; height: 65vh; overflow-y: auto"
            >
              <ElRow :gutter="20">
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="name" :label="$t('name')">
                    <GMapAutocomplete
                      id="nameGoogleAutoComplete"
                      class="googleAutoComplete pac-target-input"
                      :types="getAccommodationGoogleTypes()"
                      placeholder=""
                      :class="formattedAddressInvalid ? 'invalid' : ''"
                      v-model="form.name"
                      @place_changed="
                        ;(address = setGeolocation($event)),
                          updateAddress(address)
                      "
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="accommodation_type_uuid"
                    :label="$t('type')"
                    @keydown.enter.prevent="submit(ruleFormRef)"
                    class="autocomplete-new-entity"
                  >
                    <el-select-v2
                      :key="getAccommodationTypeKey()"
                      v-loading="getAccommodationTypeKey() === 0"
                      v-model="form.accommodation_type_uuid"
                      :remote-method="searchAccommodationTypes"
                      :placeholder="$t('select_a_type')"
                      remote
                      filterable
                      autocomplete
                      clearable
                      clear-icon="Close"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                      :options="getSearchAccommodationTypes()"
                      style="width: 100%"
                    />
                    <Icons
                      name="24px/group_purple"
                      class="new-entity-button"
                      @click="showNewAccommodationTypeDialog = true"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <ElFormItem
                    prop="location_uuid"
                    :label="$t('main_location')"
                    class="autocomplete-new-entity"
                    :class="
                      form.location_uuid ? 'autocomplete-redirection' : ''
                    "
                  >
                    <el-select-v2
                      :key="getLocationKey()"
                      v-loading="getLocationKey() === 0"
                      v-model="form.location_uuid"
                      :remote-method="searchLocations"
                      :placeholder="$t('select_a_location')"
                      remote
                      filterable
                      autocomplete
                      clearable
                      clear-icon="Close"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                      :options="getSearchLocations()"
                      style="width: 100%"
                    />
                    <Icons
                      name="24px/group_purple"
                      class="new-entity-button"
                      @click="showNewLocationDialog = true"
                    />
                    <Icons
                      v-if="form.location_uuid"
                      name="24px/arrow_top_right_purple"
                      class="new-entity-button"
                      @click="
                        newWindowItem('/locations/locations', {
                          uuid: form.location_uuid
                        })
                      "
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="formatted_address"
                    :label="$t('address')"
                    @keydown.enter.prevent="submit(ruleFormRef)"
                  >
                    <GMapAutocomplete
                      id="googleAutoComplete"
                      class="googleAutoComplete pac-target-input"
                      :types="getAccommodationGoogleTypes()"
                      :class="formattedAddressInvalid ? 'invalid' : ''"
                      v-model="form.formatted_address"
                      @place_changed="
                        ;(address = setGeolocation($event)),
                          updateAddress(address)
                      "
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="street" :label="$t('street_and_number')">
                    <ElInput
                      v-model="form.street"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="city" :label="$t('city')">
                    <ElInput
                      v-model="form.city"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="postal_code" :label="$t('postal_code')">
                    <ElInput
                      v-model="form.postal_code"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="country_uuid"
                    :label="$t('country')"
                    class="autocomplete-new-entity"
                    @keydown.enter.prevent="submit(ruleFormRef)"
                  >
                    <el-select-v2
                      :key="getCountryKey()"
                      v-loading="getCountryKey() === 0"
                      v-model="form.country_uuid"
                      :remote-method="searchCountries"
                      :placeholder="$t('select_a_country')"
                      remote
                      filterable
                      autocomplete
                      clearable
                      clear-icon="Close"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                      :options="getSearchCountries(t)"
                      style="width: 100%"
                      @change="updateProvinceArray($event)"
                    />
                    <Icons
                      name="24px/group_purple"
                      class="new-entity-button"
                      @click="showNewCountryDialog = true"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="province_uuid"
                    :label="$t('province')"
                    @keydown.enter.prevent="submit(ruleFormRef)"
                    class="autocomplete-new-entity"
                  >
                    <ElSelect
                      v-model="form.province_uuid"
                      :placeholder="$t('select_a_province')"
                      :autocomplete="true"
                      style="width: 100%"
                      :no-data-text="$t('select_first_a_country')"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                      class="no-multiple"
                      clearable
                      clear-icon="Close"
                    >
                      <el-option
                        v-for="item in provinces"
                        :key="item.uuid"
                        :label="item.name"
                        :value="item.uuid"
                      />
                    </ElSelect>
                    <Icons
                      name="24px/group_purple"
                      class="new-entity-button"
                      @click="showNewProvinceDialog = true"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="customer_price"
                    :label="$t('customer_price')"
                  >
                    <ElInputNumber
                      v-model="form.customer_price"
                      type="number"
                      min="0"
                      step="0.01"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="paradise_price"
                    :label="$t('paradise_price')"
                  >
                    <ElInputNumber
                      v-model="form.paradise_price"
                      type="number"
                      min="0"
                      step="0.01"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="commission" :label="$t('commission')">
                    <ElInputNumber
                      v-model="form.commission"
                      type="number"
                      min="0"
                      step="0.01"
                      @keydown.enter.prevent="submit(ruleFormRef)"
                    />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="public" :label="$t('public')">
                    <ElSwitch v-model="form.public" />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="24">
                  <ElFormItem prop="currency" :label="$t('currency')">
                    <ElSelect
                      v-model="form.currency"
                      :autocomplete="true"
                      :filterable="true"
                      style="width: 100%"
                      :placeholder="$t('select_currency')"
                      :fallback-placements="['top', 'bottom']"
                      :popper-options="getSelectPopperOptions()"
                      popper-class="custom-selector"
                      clearable
                      clear-icon="Close"
                    >
                      <el-option
                        v-for="item in currencies"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </ElSelect>
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem prop="prepayment" :label="$t('prepayment')">
                    <ElSwitch v-model="form.prepayment" />
                  </ElFormItem>
                </ElCol>
                <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
                  <ElFormItem
                    prop="payment_by_customer"
                    :label="$t('payment_by_customer')"
                  >
                    <ElSwitch v-model="form.payment_by_customer" />
                  </ElFormItem>
                </ElCol>
              </ElRow>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 12"
              :lg="12"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewAccommodationTypeDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_accommodation_type') }}
        </span>
      </template>
      <QuickCreateAccommodationTypeDialog
        @create="
          ;(showNewAccommodationTypeDialog = false),
            createAccommodationType($event)
        "
        @cancel="showNewAccommodationTypeDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewProvinceDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_province') }}
        </span>
      </template>
      <QuickCreateProvinceDialog
        @create=";(showNewProvinceDialog = false), createProvince($event)"
        @cancel="showNewProvinceDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewCountryDialog"
      :width="containerWidth <= 600 ? '100%' : '627px'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_country') }}
        </span>
      </template>
      <QuickCreateCountryDialog
        @create=";(showNewCountryDialog = false), createCountry($event)"
        @cancel="showNewCountryDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewLocationDialog"
      :close-on-click-modal="false"
      :width="containerWidth <= 600 ? '100%' : '627px'"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_location') }}
        </span>
      </template>
      <QuickCreateLocationDialog
        @create=";(showNewLocationDialog = false), createLocation($event)"
        @cancel="showNewLocationDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_ACCOMMODATION from '@/graphql/accommodations/mutation/createAccommodation.gql'
import CREATE_ACCOMMODATION_WITHOUT_PRICE from '@/graphql/accommodations/mutation/createAccommodationWithoutPrice.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  name: '',
  accommodation_type_uuid: '',
  location_uuid: '',
  formatted_address: '',
  street: '',
  city: '',
  postal_code: '',
  province_uuid: '',
  country_uuid: '',
  country_name: '',
  business_status: '',
  price_level: '',
  external_rating: 0,
  reservable: false,
  serves: [],
  user_ratings_total: 0,
  wheelchair_accessible_entrance: false,
  website: '',
  phone_number: '',
  phone_country_code: '',
  google_provider_id: '',
  customer_price: '',
  paradise_price: '',
  commission: '',
  prepayment: false,
  payment_by_customer: false,
  public: true,
  currency: 'eur'
})

let {
  provinces,
  countriesArray,
  address,
  containerWidth,
  formattedAddressInvalid
} = getFormInitialVariables()

const emits = defineEmits(['create'])
const { width } = useBreakpoints()
const currencies = getCurrencies()

const ruleFormRef = ref()
const loading = ref(false)
const showNewProvinceDialog = ref(false)
const showNewAccommodationTypeDialog = ref(false)
const showNewCountryDialog = ref(false)
const showNewLocationDialog = ref(false)

const rules = {
  name: { required },
  location_uuid: { required },
  formatted_address: { required }
}

onMounted(() => {
  initialAccommodationTypes()
  initialLocations()
  initialCountries()
})

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  name: [
    {
      required: true,
      message: t('name_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  location_uuid: [
    {
      required: true,
      message: t('location_is_required'),
      trigger: 'change'
    }
  ],
  formatted_address: [
    {
      required: true,
      message: t('address_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    if (!form.name || !form.formatted_address) {
      formattedAddressInvalid.value = true
    } else {
      formattedAddressInvalid.value = false
    }
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const variables = {
    name: form.name,
    accommodation_type_uuid: form.accommodation_type_uuid,
    location_uuid: form.location_uuid,
    formatted_address: form.formatted_address,
    street: form.street,
    city: form.city,
    postal_code: form.postal_code,
    province_uuid: form.province_uuid,
    country_uuid: form.country_uuid,
    latitude: form.latitude,
    longitude: form.longitude,
    business_status: form.business_status,
    price_level: form.price_level,
    public: form.public,
    external_rating: form.external_rating
      ? parseInt(form.external_rating * 10)
      : 0,
    reservable: form.reservable ?? false,
    serves: form.serves,
    user_ratings_total: form.user_ratings_total ?? 0,
    wheelchair_accessible_entrance:
      form.wheelchair_accessible_entrance ?? false,
    website: form.website,
    phone_number: form.phone_number,
    phone_country_code: form.phone_country_code,
    google_provider_id: form.google_provider_id
  }
  if (form.customer_price || form.paradise_price || form.commission) {
    variables.customer_price = form.customer_price
      ? parseInt(form.customer_price * 100)
      : 0
    variables.paradise_price = form.paradise_price
      ? parseInt(form.paradise_price * 100)
      : 0
    variables.commission = form.commission ? parseInt(form.commission * 100) : 0
    variables.prepayment = form.prepayment
    variables.payment_by_customer = form.payment_by_customer
    variables.currency = form.currency
  }
  const response = await mutation(
    form.customer_price || form.paradise_price || form.commission
      ? CREATE_ACCOMMODATION
      : CREATE_ACCOMMODATION_WITHOUT_PRICE,
    variables
  )
  if (response.error) {
    loading.value = false
    if (response.error.message.includes('input.name')) {
      $showError(t('accommodation_name_already_exists'))
    } else {
      $showError(response.error, t)
      $sentry(response.error, 'create')
    }
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createAccommodation ?? null)
    loading.value = false
  }
}

const updateAddress = async (address) => {
  if (address?.find((i) => i.field === 'name')?.value) {
    form.name = address.find((i) => i.field === 'name').value
    setGoogleAutocompleteInput(form, 'nameGoogleAutoComplete', 'name')
  }
  if (address?.find((i) => i.field === 'formatted_address')?.value) {
    form.formatted_address = address.find(
      (i) => i.field === 'formatted_address'
    ).value
    setGoogleAutocompleteInput(form)
  }
  if (address?.find((i) => i.field === 'street')?.value) {
    form.street = address.find((i) => i.field === 'street').value
  } else if (address?.find((i) => i.field === 'formatted_address')?.value) {
    form.street = address.find((i) => i.field === 'formatted_address').value
  }
  if (address?.find((i) => i.field === 'city')?.value) {
    form.city = address.find((i) => i.field === 'city').value
  }
  if (address?.find((i) => i.field === 'postal_code')?.value) {
    form.postal_code = address.find((i) => i.field === 'postal_code').value
  }
  if (address?.find((i) => i.field === 'province')?.value) {
    form.province_name = address.find((i) => i.field === 'province').value
  }
  if (address?.find((i) => i.field === 'country')?.value) {
    form.country_name = address.find((i) => i.field === 'country').value
    form.country_code = address.find((i) => i.field === 'country_code').value
    searchCountriesByCode(form.country_name)
    countriesArray = await getSearchCountriesByCode()
    if (countriesArray?.length) {
      form.country_uuid =
        countriesArray.find((i) => i.code === form.country_code)?.value ?? null
      if (form.country_uuid) {
        provinces.value = await searchProvincesByCountry(form.country_uuid)
        if (address?.find((i) => i.field === 'province')?.value) {
          form.province_name = address.find((i) => i.field === 'province').value
          form.province_uuid =
            provinces.value?.find((i) => i.name === form.province_name)?.uuid ??
            null
          if (!form.province_uuid) {
            createAsyncProvince(address)
          }
        }
      } else {
        getCountryAndProvinceByName(address)
      }
    } else {
      getCountryAndProvinceByName(address)
    }
  }
  if (address?.find((i) => i.field === 'latitude')?.value) {
    form.latitude = address.find((i) => i.field === 'latitude').value
  }
  if (address?.find((i) => i.field === 'longitude')?.value) {
    form.longitude = address.find((i) => i.field === 'longitude').value
  }
  if (address?.find((i) => i.field === 'business_status')?.value) {
    form.business_status = address.find(
      (i) => i.field === 'business_status'
    ).value
  }
  if (address?.find((i) => i.field === 'external_rating')?.value) {
    form.external_rating = address.find(
      (i) => i.field === 'external_rating'
    ).value
  }
  if (address?.find((i) => i.field === 'price_level')?.value) {
    form.price_level = address.find((i) => i.field === 'price_level').value
  }
  if (address?.find((i) => i.field === 'user_ratings_total')?.value) {
    form.user_ratings_total = parseInt(
      address.find((i) => i.field === 'user_ratings_total').value
    )
  }
  if (address?.find((i) => i.field === 'reservable')?.value) {
    form.reservable = address.find((i) => i.field === 'reservable').value
  }
  if (
    address?.find((i) => i.field === 'wheelchair_accessible_entrance')?.value
  ) {
    form.wheelchair_accessible_entrance = address.find(
      (i) => i.field === 'wheelchair_accessible_entrance'
    ).value
  }
  if (address?.find((i) => i.field === 'serves')?.value) {
    form.serves = address.find((i) => i.field === 'serves').value
  }
  if (address?.find((i) => i.field === 'website')?.value) {
    form.website = address.find((i) => i.field === 'website').value
  }
  if (address?.find((i) => i.field === 'phone_number')?.value) {
    form.phone_number = address.find((i) => i.field === 'phone_number').value
  }
  if (address?.find((i) => i.field === 'phone_country_code')?.value) {
    form.phone_country_code = address.find(
      (i) => i.field === 'phone_country_code'
    ).value
  }
  if (address?.find((i) => i.field === 'place_id')?.value) {
    form.google_provider_id = address.find((i) => i.field === 'place_id').value
  }
}
const getCountryAndProvinceByName = async (address) => {
  const provinceName = address.find((i) => i.field === 'province').value
  const province = await searchProvincesByName(provinceName)
  form.country_name = province?.country?.name
  form.country_code = province?.country?.code
  form.country_uuid = province?.country?.uuid
  if (form.country_code) {
    searchCountriesByCode(form.country_code)
    countriesArray = await getSearchCountriesByCode()
    provinces.value = await searchProvincesByCountry(form.country_uuid)
    form.province_name = address.find((i) => i.field === 'province').value
    form.province_uuid =
      provinces.value?.find((i) => i.name === form.province_name)?.uuid ?? null
  } else {
    createAsyncCountry(address)
  }
}
const updateProvinceArray = async (event) => {
  provinces.value = await searchProvincesByCountry(event)
}

const updateCountryAndProvince = async (event) => {
  searchCountries(event.name)
  provinces.value = await searchProvincesByCountry(event.uuid)
}

const createAsyncProvince = async (address) => {
  const provinceName =
    address.find((i) => i.field === 'province')?.value ?? null
  if (provinceName && form.country_uuid) {
    const province = await mutationProvince({
      name: provinceName,
      country_uuid: form.country_uuid
    })
    form.province_uuid = province.uuid
    form.province_name = province.name
    form.country_uuid = province.country?.uuid
    form.country_code = province.country?.code
    form.country_name = province.country?.name
    updateCountryAndProvince(province.country)
  }
}

const createAsyncCountry = async (address) => {
  const countryName = address.find((i) => i.field === 'country')?.value ?? null
  const countryCode =
    address.find((i) => i.field === 'country_code')?.value ?? null
  if (countryName && countryCode) {
    let country = await mutationCountry(
      { name: countryName, code: countryCode },
      t
    )
    if (country) {
      form.country_uuid = country.uuid
      form.country_code = country.code
      form.country_name = country.name
      createAsyncProvince(address)
    } else {
      country = getSearchCountriesByCode()?.find((i) => i.code === countryCode)
      if (country) {
        form.country_uuid = country?.uuid
        form.country_code = country?.code
        form.country_name = country?.name
        createAsyncProvince(address)
      }
    }
  }
}

const createAccommodationType = (event) => {
  if (event?.uuid) {
    form.accommodation_type_uuid = event.uuid
    form.accommodationType = event
    searchAccommodationTypes(event.type, form.accommodationType, null, true)
  }
}

const createProvince = async (event) => {
  if (event?.uuid) {
    form.province_uuid = event.uuid
    form.province_name = event.name
    form.country_uuid = event.country?.uuid
    form.country_code = event.country?.code
    form.country_name = event.country?.name
    updateCountryAndProvince(event.country)
  }
}

const createCountry = async (event) => {
  if (event?.uuid) {
    form.country_uuid = event.uuid
    form.country_code = event.code
    form.country_name = event.name
    updateCountryAndProvince(event)
  }
}

const createLocation = (event) => {
  if (event?.uuid) {
    form.location_uuid = event.uuid
    form.location = event
    searchLocations(event.name, form.location, null, true)
  }
}
</script>
